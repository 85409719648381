$( document ).ready(function() {
	$('#search').hideseek({
		list: '.list',
		highlight: true,
		hidden_mode:true,
		attribute: 'title',
		nodata: 'Nici un arendator gasit'
	});
	$(".show-details").hide();
	$('.add-contract').click(function(){
		var url = "addnew";
		var token = $("input[name=_token]").val();
		var id_arendator = $('input[name=id_arendator]:checked').val();
		var contract_nr_contract = $("#nr_contract").val();
		var contract_data_contract = $("#data_contract").val();
		var contract_perioada_contract = $("#perioada_contract").val();
		var contract_nivel_arenda = $("#nivel_arenda").val();
		var contract_zona = $("#zona").val();
		var contract_act_proprietate = $("#act_proprietate").val();
		var contract_nr_act_proprietate = $("#nr_act_proprietate").val();
		var contract_data_act_proprietate = $("#data_act_proprietate").val();
		var contract_suprafata = $("#suprafata").val();
		var contract_tarla = $("#tarla").val();
		var contract_parcela = $("#parcela").val();
		var contract_vecin_nord = $("#vecin_nord").val();
		var contract_vecin_est = $("#vecin_est").val();
		var contract_vecin_sud = $("#vecin_sud").val();
		var contract_vecin_vest = $("#vecin_vest").val();
		var contract_categorie_teren = $("#categorie_teren").val();
		var contract_observatii = $("#observatii").val();
		$.ajax({
			url: url,
			type: "POST",
			dataType: 'json',
			data: {
				'_token': token,
				'id_arendator': id_arendator,
				'nr_contract': contract_nr_contract,
				'data_contract': contract_data_contract,
				'perioada_contract': contract_perioada_contract,
				'nivel_arenda': contract_nivel_arenda,
				'id_zona': contract_zona,
 				'act_proprietate': contract_act_proprietate,
 				'nr_act_proprietate': contract_nr_act_proprietate,
				'data_act_proprietate': contract_data_act_proprietate,
				'suprafata': contract_suprafata,
				'tarla': contract_tarla,
				'parcela': contract_parcela,
				'vecin_nord': contract_vecin_nord,
				'vecin_est': contract_vecin_est,
				'vecin_sud': contract_vecin_sud,
				'vecin_vest': contract_vecin_vest,
				'categorie_teren': contract_categorie_teren,
				'observatii': contract_observatii
			},
			success: function(data){
				if(data.status == 'success'){
					$(".alert").removeClass("alert-danger");
					$(".alert").addClass("alert-success");
					$('#form-contract').trigger("reset");
				}
				else{
					$(".alert").addClass("alert-danger");
					$(".alert").removeClass("alert-success");
				}
				$(".alert").show();
				$(".message").html(data.error);
			},
			error: function(data){
				var errors = $.parseJSON(data.responseText);
				$(".alert").show();
				$(".message").text("");
				$(".alert").addClass("alert-danger");
				$.each(errors, function(index, value) {
					$(".message").append(value + '<br>');
				});
			}
		});
	});
	$(".delete-contract").click(function(){
        var url = "delete";
        var id_contract = $(this).data('id');
        var token = $("input[name=_token]").val();
        bootbox.confirm("Sunteti sigur?", function(result) {
            if(result == true){
                $.ajax({
                    url: url,
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        'id_contract': id_contract,
                        '_token': token
                    },
                    success: function(data){
                        bootbox.alert("Contract sters", function(){
                            window.location.reload(true);
                        });
                    }
                });
            }
        });
    });
	$('.edit-contract').click(function(){
        var url = "../update";
        var token = $("input[name=_token]").val();
        var id_contract = $(this).data('id');
        var contract_nr_contract = $("#nr_contract").val();
        var contract_data_contract = $("#data_contract").val();
        var contract_perioada_contract = $("#perioada_contract").val();
        var contract_nivel_arenda = $("#nivel_arenda").val();
        var contract_zona = $("#zona").val();
        var contract_act_proprietate = $("#act_proprietate").val();
        var contract_nr_act_proprietate = $("#nr_act_proprietate").val();
        var contract_data_act_proprietate = $("#data_act_proprietate").val();
        var contract_suprafata = $("#suprafata").val();
        var contract_tarla = $("#tarla").val();
        var contract_parcela = $("#parcela").val();
        var contract_vecin_nord = $("#vecin_nord").val();
        var contract_vecin_est = $("#vecin_est").val();
        var contract_vecin_sud = $("#vecin_sud").val();
        var contract_vecin_vest = $("#vecin_vest").val();
        var contract_categorie_teren = $("#categorie_teren").val();
		var contract_observatii = $("#observatii").val();
        $.ajax({
            url: url,
            type: "POST",
            dataType: 'json',
            data: {
                '_token': token,
                'id_contract': id_contract,
                'nr_contract': contract_nr_contract,
                'data_contract': contract_data_contract,
                'perioada_contract': contract_perioada_contract,
                'nivel_arenda': contract_nivel_arenda,
                'id_zona': contract_zona,
                'act_proprietate': contract_act_proprietate,
                'nr_act_proprietate': contract_nr_act_proprietate,
                'data_act_proprietate': contract_data_act_proprietate,
                'suprafata': contract_suprafata,
                'tarla': contract_tarla,
                'parcela': contract_parcela,
                'vecin_nord': contract_vecin_nord,
                'vecin_est': contract_vecin_est,
                'vecin_sud': contract_vecin_sud,
                'vecin_vest': contract_vecin_vest,
                'categorie_teren': contract_categorie_teren,
				'observatii': contract_observatii
            },
            success: function(data){
                if(data.status == 'success'){
                    $(".alert").removeClass("alert-danger");
                    $(".alert").addClass("alert-success");
                    $('#form-contract').trigger("reset");
                }
                else{
                    $(".alert").addClass("alert-danger");
                    $(".alert").removeClass("alert-success");
                }
                $(".alert").show();
                $(".message").html(data.error);
            },
            error: function(data){
                var errors = $.parseJSON(data.responseText);
                $(".alert").show();
                $(".message").text("");
                $(".alert").addClass("alert-danger");
                $.each(errors, function(index, value) {
                    $(".message").append(value + '<br>');
                });
            }
        });
    });
	$('.adauga-teren').click(function(){
		var contract_id = $(this).data('id');
		var el = $('.form-adauga-teren[data-contract-id="' + contract_id  + '"]');
		el.toggle( "slow" );
	});
	$('.add-teren-contract').click(function(){
		var url = "addteren";
		var contract_id = $(this).data('contract-id');
		var token = $('#form-adauga-teren-' + contract_id + ' input[name=_token]').val();
		var contract_zona = $("#zona-" + contract_id).val();
        var contract_act_proprietate = $("#act_proprietate_" + contract_id).val();
        var contract_nr_act_proprietate = $("#nr_act_proprietate_" + contract_id).val();
        var contract_data_act_proprietate = $("#data_act_proprietate_" + contract_id).val();
        var contract_suprafata = $("#suprafata_" + contract_id).val();
        var contract_tarla = $("#tarla_" + contract_id).val();
        var contract_parcela = $("#parcela_" + contract_id).val();
        var contract_vecin_nord = $("#vecin_nord_" + contract_id).val();
        var contract_vecin_est = $("#vecin_est_" + contract_id).val();
        var contract_vecin_sud = $("#vecin_sud_" + contract_id).val();
        var contract_vecin_vest = $("#vecin_vest_" + contract_id).val();
        var contract_categorie_teren = $("#categorie_teren_" + contract_id).val();
        $.ajax({
            url: url,
            type: "POST",
            dataType: 'json',
            data: {
                '_token': token,
                'id_contract': contract_id,
                'id_zona': contract_zona,
                'act_proprietate': contract_act_proprietate,
                'nr_act_proprietate': contract_nr_act_proprietate,
                'data_act_proprietate': contract_data_act_proprietate,
                'suprafata': contract_suprafata,
                'tarla': contract_tarla,
                'parcela': contract_parcela,
                'vecin_nord': contract_vecin_nord,
                'vecin_est': contract_vecin_est,
                'vecin_sud': contract_vecin_sud,
                'vecin_vest': contract_vecin_vest,
                'categorie_teren': contract_categorie_teren
            },
            success: function(data){
                if(data.status == 'success'){
					bootbox.alert("Teren adaugat", function(){
						window.location.reload(true);
					});
                }
                else{
                    $("#alert-" + contract_id).addClass("alert-danger");
                    $("#alert-" + contract_id).removeClass("alert-success");
                }
                $("#alert-" + contract_id).show();
                $("#message-" + contract_id).html(data.error);
            },
            error: function(data){
                var errors = $.parseJSON(data.responseText);
                $(".alert").show();
                $(".message").text("");
                $(".alert").addClass("alert-danger");
                $.each(errors, function(index, value) {
                    $(".message").append(value + '<br>');
                });
            }
        });
	});
});
