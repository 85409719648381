$( document ).ready(function() {
	$(".edit-teren").click(function(){
    	var url = "../update";
		var token = $("input[name=_token]").val();
		var teren_id = $(this).data('id');
		var zona = $("#zona").val();
		var act_proprietate = $("#act_proprietate").val();
		var suprafata = $("#suprafata").val();
		var tarla = $("#tarla").val();
		var parcela = $("#parcela").val();
		var vecin_nord = $("#vecin_nord").val();
		var vecin_est = $("#vecin_est").val();
		var vecin_sud = $("#vecin_sud").val();
		var vecin_vest = $("#vecin_vest").val();
		var categorie_teren = $("#categorie_teren").val();
    	$.ajax({
        	url: url,
        	type: 'POST',
        	dataType: 'json',
        	data: {
            	'_token': token,
				'id_teren': teren_id,
				'id_zona': zona,
				'act_proprietate': act_proprietate,
				'suprafata': suprafata,
				'tarla': tarla,
				'parcela': parcela,
				'vecin_nord': vecin_nord,
				'vecin_est': vecin_est,
				'vecin_sud': vecin_sud,
				'vecin_vest': vecin_vest,
				'categorie_teren': categorie_teren
        	},
        	success: function(data){
            	if(data.status == 'success'){
                	$(".alert").removeClass("alert-danger");
                	$(".alert").addClass("alert-success");
           		}
            	else{
                	$(".alert").addClass("alert-danger");
                	$(".alert").removeClass("alert-success");
            	}
            	$(".alert").show();
            	$(".message").text(data.error);
            	console.log("message is " + data.error);
        	},
        	error:function(data) {
            	console.log("error is " + data);
            	var errors = $.parseJSON(data.responseText);
            	$(".alert").show();
            	$(".alert").addClass("alert-danger");
            	$.each(errors, function(index, value) {
                	$(".message").append(value);
                	console.log("Error: " + value);
            	});
        	}
    	});
	});
	$('.seteaza-cultura').click(function(){
		var id_teren = $(this).data('teren-id');
		var form = 'form-culturi-' + id_teren;
		var url = "setcultura";
		var token = $("input[name=_token]").val();
		var cultura = $('#cultura-' + id_teren).val().split('#');
		var current_teren = $(this);
    	$.ajax({
        	url: url,
        	type: 'POST',
        	dataType: 'json',
        	data: {
            	'_token': token,
				'id_teren': id_teren,
				'id_cultura': cultura[0]
        	},
        	success: function(data){
            	if(data.status == 'success'){
					console.log(cultura[1]);
                	$(".alert").removeClass("alert-danger");
                	$(".alert").addClass("alert-success");
					$(current_teren).parents('td').html(cultura[1]);
           		}
            	else{
                	$(".alert").addClass("alert-danger");
                	$(".alert").removeClass("alert-success");
            	}
            	$(".alert").show();
            	$(".message").text(data.error);
            	console.log("message is " + data.error);
        	},
        	error:function(data) {
            	console.log("error is " + data);
            	var errors = $.parseJSON(data.responseText);
            	$(".alert").show();
            	$(".alert").addClass("alert-danger");
            	$.each(errors, function(index, value) {
                	$(".message").append(value);
                	console.log("Error: " + value);
            	});
        	}
    	});
		console.log(id_teren);
	});
});
