$( document ).ready(function() {
  $(".alert").hide();
});
$('.close').on('click', function () {
	$(".alert").hide();
})
$(".add-zone").click(function(){
    var url = "addnew";
	var zone_name = $("#nume").val();
	var token = $("input[name=_token]").val();
	console.log("sending to server " + zone_name + " and " + token);
	$.ajax({
		url: url,
		type: "POST",
		dataType: 'json',
		data: {
			'zone_name': zone_name,
			'_token': token
		},
		success: function(data){
			if(data.status == 'success'){
				$(".alert").removeClass("alert-danger");
            	$(".alert").addClass("alert-success");
				$("#nume").val("");
			}
			else{
				$(".alert").addClass("alert-danger");
                $(".alert").removeClass("alert-success");
			}
			$(".alert").show();
			$(".message").html(data.error);
			console.log("message is " + data.error);
		},
		error: function(data){
			console.log("error is " + data);
			var errors = $.parseJSON(data.responseText);
			$(".alert").show();
			$(".alert").addClass("alert-danger");
			$.each(errors, function(index, value) {
				$(".message").append(value);
				console.log("Error: " + value);
    		});
		}
	});
});
$(".delete").click(function(){
	var url = "delete";
	var target = $(this).data('target');
	var target_id = $(this).data('id');
	var token = $("input[name=_token]").val();
	bootbox.confirm("Are you sure?", function(result) {
		if(result == true){
			$.ajax({
				url: url,
				type: 'POST',
				dataType: 'json',
				data: {
					'id_zona': target_id,
					'_token': token
				},
				success: function(data){
					bootbox.alert("Zona stearsa", function(){
						window.location.reload(true);
					});
				}
			});
		}
	}); 
});
$(".edit-zone").click(function(){
	var url = "../update";
	var target_name = $('#nume').val();
    var target_id = $('#nume').data('id');
	var token = $("input[name=_token]").val();
	$.ajax({
		url: url,
		type: 'POST',
		dataType: 'json',
		data: {
			'target_id': target_id,
			'target_name': target_name,
			'_token': token
		},
		success: function(data){
			if(data.status == 'success'){
                $(".alert").removeClass("alert-danger");
                $(".alert").addClass("alert-success");
            }
            else{
                $(".alert").addClass("alert-danger");
                $(".alert").removeClass("alert-success");
            }
            $(".alert").show();
            $(".message").text(data.error);
            console.log("message is " + data.error);
		},
		error:function(data) {
			console.log("error is " + data);
            var errors = $.parseJSON(data.responseText);
            $(".alert").show();
            $(".alert").addClass("alert-danger");
            $.each(errors, function(index, value) {
                $(".message").append(value);
                console.log("Error: " + value);
            });

		}
	});
	console.log("sending id=" + target_id + ", name=" + target_name + " and token=" + token);
});

