$( document ).ready(function() {
	$(".add-contract-section").hide();
	$(".show-details").hide();
	$("#add_contract").click(function(){
		var thisCheck = $(this);
		if(thisCheck.is(':checked')){
			$(".add-contract-section").show();
		}
		else{
			$(".add-contract-section").hide();
		}
	});
	$('.add-client').click(function(){
		var url = "addnew";
		var token = $("input[name=_token]").val();
    	var client_nume = $("#nume").val();
		var client_adresa = $("#adresa").val();
		var client_cnp = $("#cnp").val();
		var client_ci_serie = $("#ci_serie").val();
		var client_ci_nr = $("#ci_nr").val();
		var client_ci_el = $("#ci_el").val();
		var client_ci_data = $("#ci_data").val();
		var client_telefon = $("#telefon").val();
		var contract_nr_contract = $("#nr_contract").val();
		var contract_data_contract = $("#data_contract").val();
		var contract_perioada_contract = $("#perioada_contract").val();
		var contract_nivel_arenda = $("#nivel_arenda").val();
		var contract_zona = $("#zona").val();
		var contract_act_proprietate = $("#act_proprietate").val();
		var contract_nr_act_proprietate = $("#nr_act_proprietate").val();
		var contract_data_act_proprietate = $("#data_act_proprietate").val();
		var contract_suprafata = $("#suprafata").val();
		var contract_tarla = $("#tarla").val();
		var contract_parcela = $("#parcela").val();
		var contract_vecin_nord = $("#vecin_nord").val();
		var contract_vecin_est = $("#vecin_est").val();
		var contract_vecin_sud = $("#vecin_sud").val();
		var contract_vecin_vest = $("#vecin_vest").val();
		var contract_categorie_teren = $("#categorie_teren").val();
		var contract_observatii = $("#observatii").val();
		var adauga_contract = "0";
		console.log(contract_observatii);
		if($("#add_contract").is(':checked')){
			adauga_contract = "1"
		}
		else{
			adauga_contract = "0";
		}
    	$.ajax({
        	url: url,
        	type: "POST",
        	dataType: 'json',
        	data: {
            	'_token': token,
            	'nume': client_nume,
				'adresa': client_adresa,
				'cnp': client_cnp,
				'ci_serie': client_ci_serie,
				'ci_nr': client_ci_nr,
				'ci_el': client_ci_el,
				'ci_data': client_ci_data,
				'telefon': client_telefon,
				'adauga_contract': adauga_contract,
				'nr_contract': contract_nr_contract,
				'data_contract': contract_data_contract,
				'perioada_contract': contract_perioada_contract,
				'nivel_arenda': contract_nivel_arenda,
				'id_zona': contract_zona,
				'act_proprietate': contract_act_proprietate,
				'suprafata': contract_suprafata,
				'tarla': contract_tarla,
				'parcela': contract_parcela,
				'vecin_nord': contract_vecin_nord,
				'vecin_est': contract_vecin_est,
				'vecin_sud': contract_vecin_sud,
				'vecin_vest': contract_vecin_vest,
				'categorie_teren': contract_categorie_teren,
				'observatii': contract_observatii
        	},
        	success: function(data){
            	if(data.status == 'success'){
                	$(".alert").removeClass("alert-danger");
                	$(".alert").addClass("alert-success");
                	$('#form-clienti').trigger("reset");
					$(".add-contract-section").hide();
            	}
            	else{
                	$(".alert").addClass("alert-danger");
                	$(".alert").removeClass("alert-success");
            	}
            	$(".alert").show();
            	$(".message").html(data.error);
        	},
        	error: function(data){
            	var errors = $.parseJSON(data.responseText);
            	$(".alert").show();
				$(".message").text("");
            	$(".alert").addClass("alert-danger");
            	$.each(errors, function(index, value) {
                	$(".message").append(value + '<br>');
            	});
        	}
    	});
	});
	$(".edit-client").click(function(){
    	var url = "../update";
		var token = $("input[name=_token]").val();
		var client_id = $('#nume').data('id');
    	var client_name = $('#nume').val();
    	var client_adresa = $('#adresa').val();
		var client_cnp = $('#cnp').val();
		var client_ci_serie = $('#ci_serie').val();
		var client_ci_nr = $('#ci_nr').val();
		var client_ci_el = $('#ci_el').val();
		var client_ci_data = $('#ci_data').val();
		var client_telefon = $('#telefon').val();
    	$.ajax({
        	url: url,
        	type: 'POST',
        	dataType: 'json',
        	data: {
            	'_token': token,
				'id_arendator': client_id,
				'nume': client_name,
				'adresa': client_adresa,
				'cnp': client_cnp,
				'ci_serie': client_ci_serie,
				'ci_nr': client_ci_nr,
				'ci_el': client_ci_el,
				'ci_data': client_ci_data,
				'telefon': client_telefon
        	},
        	success: function(data){
            	if(data.status == 'success'){
                	$(".alert").removeClass("alert-danger");
                	$(".alert").addClass("alert-success");
           		}
            	else{
                	$(".alert").addClass("alert-danger");
                	$(".alert").removeClass("alert-success");
            	}
            	$(".alert").show();
            	$(".message").text(data.error);
            	console.log("message is " + data.error);
        	},
        	error:function(data) {
            	console.log("error is " + data);
            	var errors = $.parseJSON(data.responseText);
            	$(".alert").show();
            	$(".alert").addClass("alert-danger");
            	$.each(errors, function(index, value) {
                	$(".message").append(value);
                	console.log("Error: " + value);
            	});
        	}
    	});
	});
	$(".delete-client").click(function(){
    	var url = "delete";
		var target = $(this).data('target');
    	var target_id = $(this).data('id');
    	var token = $("input[name=_token]").val();
    	bootbox.confirm("Sunteti sigur?<br>Toate contractele si terenurile atasate clientului vor fi sterse.", function(result) {
        	if(result == true){
            	$.ajax({
                	url: url,
                	type: 'POST',
                	dataType: 'json',
                	data: {
                    	'id_arendator': target_id,
                    	'_token': token
                	},
                	success: function(data){
                    	bootbox.alert("Client sters", function(){
                       		window.location.reload(true);
                    	});
                	}
            	});
        	}
    	});
	});
	$(".detalii-client").click(function(){
		var client_id = $(this).data('id');
		var el = $('.show-details[data-id="' + client_id  + '"]');
		el.toggle( "slow" );
		console.log(el.data('id'));
	});
});
